import { AuthorityType } from 'ah-api-gateways';
import { AuthStore } from 'ah-common-stores';

export interface DashboardItem {
  title: string;
  iconClass?: string;
  class?: string;
  anyAuthorities?: AuthorityType[];
  allAuthorities?: AuthorityType[];
}

export interface DashboardLink extends DashboardItem {
  link: string;
}

export interface DashboardLinkGroup extends DashboardItem {
  name: string;
  collapsible?: boolean;
  children: (DashboardLinkGroup | DashboardLink)[];
}

export interface DashboardParentLinkGroup {
  name: string;
  title: string;
  iconClass: string;
  children: (DashboardLinkGroup | DashboardLink)[];
}

const peopleAndEntitiesLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'People & Entities',
  iconClass: 'fas fa-users',
  name: 'people',
  children: [
    {
      title: 'Partners',
      anyAuthorities: [AuthorityType.VIEW_ALL_PARTNERS],
      iconClass: 'fas fa-landmark',
      link: '/dashboard/partners',
    },
    {
      link: '/dashboard/agents',
      allAuthorities: [AuthorityType.VIEW_MEMBERS, AuthorityType.VIEW_MEMBER_REPORTS, AuthorityType.VIEW_USER_ACCOUNTS],
      iconClass: 'fas fa-user-astronaut',
      title: 'Agents',
    },
    {
      link: '/dashboard/clients',
      anyAuthorities: [AuthorityType.VIEW_CLIENTS],
      iconClass: 'fas fa-building',
      title: 'Clients',
    },
    {
      link: '/dashboard/individuals',
      allAuthorities: [AuthorityType.VIEW_MEMBERS, AuthorityType.VIEW_MEMBER_REPORTS, AuthorityType.VIEW_USER_ACCOUNTS],
      iconClass: 'fas fa-users',
      title: 'Individuals',
    },
  ],
};

const tradingLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'Trading',
  iconClass: 'fas fa-exchange-alt',
  name: 'trading',
  children: [
    {
      link: '/dashboard/trades',
      anyAuthorities: [AuthorityType.VIEW_TRADES],
      iconClass: 'fas fa-exchange-alt',
      title: 'Trades',
    },
    {
      link: '/dashboard/kill-switch',
      anyAuthorities: [AuthorityType.RESUME_SUSPEND_TRADING],
      iconClass: 'fas fa-toggle-on',
      title: 'Kill Switch',
    },
    {
      link: '/dashboard/collateral-settings',
      anyAuthorities: [AuthorityType.VIEW_COLLATERAL_CONFIGURATIONS],
      iconClass: 'fas fa-sliders-h',
      title: 'Collateral Settings',
    },
    {
      link: '/dashboard/trader-obo',
      allAuthorities: [AuthorityType.ACT_ON_BEHALF_OF, AuthorityType.VIEW_CLIENTS],
      iconClass: 'fas fa-theater-masks',
      title: 'Trading OBO',
    },
    {
      link: '/dashboard/dynamic-pnl',
      anyAuthorities: [AuthorityType.VIEW_PNL_REPORTS],
      iconClass: 'fas fa-file-invoice-dollar',
      title: 'Dynamic PNL Report',
    },
  ],
};

const financeLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'Finance',
  iconClass: 'fas fa-money-bill-wave',
  name: 'finance',
  children: [
    {
      link: '/dashboard/cobbalances',
      anyAuthorities: [AuthorityType.VIEW_COB_REPORTS],
      iconClass: 'fas fa-chart-line',
      title: 'COB Balances',
    },
    {
      link: '/dashboard/settled-commissions',
      anyAuthorities: [AuthorityType.VIEW_SETTLED_COMMISSIONS_REPORTS],
      iconClass: 'fas fa-gavel',
      title: 'Settled Commissions',
    },
  ],
};

const riskLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'Risk Management',
  iconClass: 'fas fa-tasks',
  name: 'credit-control',
  children: [
    {
      link: '/dashboard/credit-and-limits',
      anyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
      iconClass: 'fas fa-chart-line',
      title: 'Credit & Limits',
    },

    // TODO - DO: MCF and Position Limits screens will be hidden at the moment in favour of the new Credit and Limits screen, this will be completely removed at a later stage.
    // {
    //   link: '/dashboard/margin-credit-facility',
    //   anyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
    //   iconClass: 'fas fa-chart-line',
    //   title: 'Margin Credit Facility',
    // },
    // {
    //   link: '/dashboard/position-limits',
    //   anyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
    //   iconClass: 'fas fa-chart-bar',
    //   title: 'Position Limits',
    // },
    {
      link: '/dashboard/risk-monitors',
      anyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
      iconClass: 'fas fa-chart-pie',
      title: 'Risk Monitors',
    },
    {
      link: '/dashboard/aml-monitor',
      iconClass: 'fas fa-chart-pie',
      title: 'AML Monitoring',
      anyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
    },
    {
      link: '/dashboard/delta-risk',
      anyAuthorities: [AuthorityType.VIEW_DELTA_RISK_REPORTS],
      iconClass: 'fas fa-chart-line',
      title: 'Client Delta Risk',
    },
    {
      link: '/dashboard/eod-market-data',
      iconClass: 'fas fa-chart-pie',
      title: 'EOD Market Data',
      anyAuthorities: [AuthorityType.VIEW_EOD_SPOT_RATES_REPORTS],
    },
  ],
};

const feeLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'Fee Management',
  iconClass: 'fas fa-money-bill-wave',
  name: 'feeManagement',
  anyAuthorities: [AuthorityType.VIEW_FEES],
  children: [
    {
      link: '/dashboard/payment-fees',
      iconClass: 'fas fa-money-bill-alt',
      title: 'Payment Fees',
    },
    {
      link: '/dashboard/funding-fees',
      iconClass: 'fas fa-money-bill-alt',
      title: 'Funding Fees',
    },
    {
      link: '/dashboard/outstanding-fees',
      iconClass: 'fas fa-money-bill',
      title: 'Outstanding Fees',
      anyAuthorities: [AuthorityType.VIEW_OUTSTANDING_FEE_REPORTS],
    },
  ],
};

const onboardingLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'Compliance',
  iconClass: 'fas fa-user-shield',
  name: 'compliance',
  children: [
    {
      link: '/dashboard/compliance/open-cases',
      anyAuthorities: [AuthorityType.VIEW_COMPLIANCE_CASES],
      iconClass: 'fas fa-folder-open',
      title: 'Open Cases',
    },
    {
      link: '/dashboard/compliance/approved-cases',
      anyAuthorities: [AuthorityType.VIEW_COMPLIANCE_CASES],
      iconClass: 'fas fa-folder',
      title: 'Approved Cases',
    },
    {
      link: '/dashboard/compliance/rejected-cases',
      anyAuthorities: [AuthorityType.VIEW_COMPLIANCE_CASES],
      iconClass: 'fas fa-folder',
      title: 'Rejected Cases',
    },
    {
      link: '/dashboard/compliance/countries',
      anyAuthorities: [AuthorityType.EDIT_SANCTIONED_COUNTRIES],
      iconClass: 'fas fa-globe',
      title: 'Countries',
    },
  ],
};

const reconciliationLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'Reconciliation',
  iconClass: 'fas fa-handshake',
  name: 'reconciliation',
  anyAuthorities: [AuthorityType.VIEW_RECONCILIATIONS_REPORTS],
  children: [
    {
      link: '/dashboard/reconciliation/house-account',
      iconClass: 'fas fa-money-bill-alt',
      title: 'House account',
    },
    {
      link: '/dashboard/reconciliation/partner-wallets',
      iconClass: 'fas fa-user',
      title: 'Partner Wallets',
    },
    {
      link: '/dashboard/reconciliation/client-wallets',
      iconClass: 'fas fa-user',
      title: 'Client Wallets',
    },
    /* FIXME uncomment when API is done
    {
      link: '/dashboard/reconciliation/funding',
      iconClass: 'fas fa-money-bill-alt',
      title: 'Funding',
    }, */ {
      link: '/dashboard/reconciliation/payments',
      iconClass: 'fas fa-money-bill-alt',
      title: 'Payments',
    },
    {
      link: '/dashboard/reconciliation/transfers',
      iconClass: 'fas fa-exchange-alt',
      title: 'Transfers',
    },
  ],
};

const paymentsAndSettlementsLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'Payments and Settlements',
  iconClass: 'fas fa-clipboard-list',
  name: 'backoffice',
  children: [
    {
      link: '/dashboard/upcoming-settlements',
      iconClass: 'fas fa-calendar-check',
      title: 'Settlements',
      anyAuthorities: [AuthorityType.VIEW_SETTLEMENT_REPORTS],
    },
    {
      link: '/dashboard/payments',
      iconClass: 'fas fa-calendar-check',
      title: 'Payments',
    },
    {
      link: '/dashboard/beneficiary-fields',
      iconClass: 'fas fa-tasks',
      title: 'Beneficiary Fields',
      anyAuthorities: [AuthorityType.UPDATE_MANDATORY_BENEFICIARY_FIELDS],
    },
  ],
};

const notificationsLinkGroup: DashboardLink = {
  link: '/dashboard/updates',
  iconClass: 'fas fa-bullhorn',
  title: 'Software Updates',
  anyAuthorities: [AuthorityType.VIEW_SOFTWARE_UPDATES],
};

const voiceTradingDeskLinkGroup: DashboardLinkGroup = {
  collapsible: true,
  title: 'Manual Input',
  iconClass: 'fas fa-clipboard-list',
  name: 'voice-trade',
  children: [
    {
      link: '/dashboard/voice-trading-desk/trades',
      iconClass: 'fas fa-exchange-alt',
      title: 'Trades Viewer',
      anyAuthorities: [AuthorityType.VIEW_VOICE_TRADES],
    },
    {
      link: '/dashboard/voice-trading-desk/house-cash',
      iconClass: 'fas fa-exchange-alt',
      title: 'House Cash',
      anyAuthorities: [AuthorityType.VIEW_VOICE_TRADES],
    },
    {
      link: '/dashboard/voice-trading-desk/transactions',
      iconClass: 'fas fa-exchange-alt',
      title: 'Transactions Viewer',
      allAuthorities: [AuthorityType.VIEW_CASH_TRANSACTION_REPORTS],
    },
    {
      link: '/dashboard/voice-trading-desk/confirmation-expiry',
      iconClass: 'fas fa-clipboard-check',
      title: 'Create Confirmations',
      anyAuthorities: [AuthorityType.MANAGE_VOICE_TRADES],
    },
    {
      title: 'Reports',
      iconClass: 'fas fa-clipboard-list',
      name: 'voice-reports',
      collapsible: true,
      anyAuthorities: [AuthorityType.VIEW_VOICE_TRADES],
      children: [
        {
          link: '/dashboard/voice-trading-desk/clients',
          iconClass: 'fas fa-users ',
          title: 'Client Reports',
        },
        {
          link: '/dashboard/voice-trading-desk/settlements',
          iconClass: 'fas fa-exchange-alt',
          title: 'Settlements',
        },
        {
          link: '/dashboard/voice-trading-desk/open-position',
          iconClass: 'fas fa-tasks',
          title: 'Open Positions',
        },
      ],
    },
    {
      link: '/dashboard/voice-trading-desk/liquidity-providers',
      iconClass: 'fas fa-money-bill',
      title: 'Liquidity Providers',
      allAuthorities: [AuthorityType.VIEW_LIQUIDITY_PROVIDER_REPORTS],
    },
    {
      link: '/dashboard/voice-trading-desk/bank-accounts',
      iconClass: 'fas fa-money-bill-wave',
      title: 'Bank Accounts',
      allAuthorities: [AuthorityType.VIEW_BANK_ACCOUNT_REPORTS],
    },
    {
      link: '/dashboard/voice-trading-desk/bulk-import',
      iconClass: 'fas fa-file-excel',
      title: 'Bulk Import',
      allAuthorities: [AuthorityType.MANAGE_VOICE_TRADES, AuthorityType.MANAGE_CASH_TRANSACTIONS],
    },
    {
      link: '/dashboard/voice-trading-desk/sucden-file-import',
      iconClass: 'fas fa-file',
      title: 'Sucden File Import',
      allAuthorities: [AuthorityType.MANAGE_VOICE_TRADES, AuthorityType.MANAGE_CASH_TRANSACTIONS],
    },
    {
      link: '/dashboard/voice-trading-desk/coutts-file-import',
      iconClass: 'fas fa-file',
      title: 'Coutts Import',
      allAuthorities: [AuthorityType.MANAGE_COUTTS_BALANCES],
    },
  ],
};

const userManagementLinkGroup: DashboardLinkGroup = {
  collapsible: false,
  title: 'User Management',
  class: 'menu-header',
  name: 'user-management',
  children: [
    {
      link: '/dashboard/user-management/users',
      iconClass: 'fas fa-users',
      title: 'Users',
      allAuthorities: [AuthorityType.VIEW_USER_ACCOUNTS, AuthorityType.VIEW_USER_PERMISSIONS],
    },
    {
      link: '/dashboard/user-management/roles-permissions',
      iconClass: 'fas fa-user-shield',
      title: 'Roles & Permissions',
      anyAuthorities: [AuthorityType.VIEW_ROLE_AUTHORITIES],
    },
  ],
};

const virtualWalletsLink: DashboardLink = {
  link: '/dashboard/virtual-wallets',
  iconClass: 'fas fa-hand-holding-dollar',
  title: 'Virtual Wallets',
  anyAuthorities: [AuthorityType.VIEW_WALLETS],
};

const liquidityManagementLink: DashboardLink = {
  link: '/dashboard/liquidity-management',
  iconClass: 'fas fa-file-invoice-dollar',
  title: 'Liquidity Management',
  anyAuthorities: [AuthorityType.VIEW_WALLETS],
};

export const sideNavLinks: DashboardLinkGroup[] = [
  {
    name: 'front-office-header',
    title: 'Front Office',
    class: 'menu-header',
    children: [peopleAndEntitiesLinkGroup, onboardingLinkGroup],
    iconClass: '',
    collapsible: false,
  },
  {
    name: 'middle-office-header',
    title: 'Middle Office',
    class: 'menu-header',
    children: [tradingLinkGroup, riskLinkGroup, financeLinkGroup, voiceTradingDeskLinkGroup, liquidityManagementLink],
    iconClass: '',
    collapsible: false,
  },
  {
    name: 'back-office-header',
    title: 'Back Office',
    class: 'menu-header',
    children: [paymentsAndSettlementsLinkGroup, reconciliationLinkGroup, feeLinkGroup, virtualWalletsLink],
    iconClass: '',
    collapsible: false,
  },
  {
    name: 'marketing-header',
    title: 'Marketing',
    class: 'menu-header',
    children: [notificationsLinkGroup],
    iconClass: '',
    collapsible: false,
  },
  userManagementLinkGroup,
];

export function getFirstAvailableLink(authStore: AuthStore) {
  const searchInGroup = (group: DashboardLinkGroup): string | undefined => {
    for (let f = 0; f < group.children.length; f++) {
      const item = group.children[f];
      if (
        (item.allAuthorities?.length && !authStore.hasAuthorities(item.allAuthorities)) ||
        (item.anyAuthorities?.length && !authStore.hasAuthorities(item.anyAuthorities, false))
      ) {
        continue;
      }
      return (item as DashboardLinkGroup).children
        ? searchInGroup(item as DashboardLinkGroup)
        : (item as DashboardLink).link;
    }
    return undefined;
  };

  for (let i = 0; i < sideNavLinks.length; i++) {
    const group = sideNavLinks[i];
    if (
      (group.allAuthorities?.length && !authStore.hasAuthorities(group.allAuthorities)) ||
      (group.anyAuthorities?.length && !authStore.hasAuthorities(group.anyAuthorities, false))
    ) {
      continue;
    }
    return searchInGroup(group);
  }

  return undefined;
}
